import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import OtpInput from "@bachdgvn/vue-otp-input";

import axios from 'axios'

import { init } from './creative/init'
import checkUpdate from './components/creative/checkUpdate'

init().then((content) => {
  
  Vue.prototype.$content = content.data
  store.dispatch('creativeAuth/autoLogin')
  
  
  Vue.prototype.$http = axios
  
  Vue.component("v-otp-input", OtpInput);
  Vue.component("checkUpdate", checkUpdate);
  Vue.use(VueTelInput)
  
  Vue.config.productionTip = false
  
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

})