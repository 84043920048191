<template>
  <transition name="fade">
    <div class="maj" v-if="show || loader">
      <template v-if="loader">
        <logo class="logo" width="128"></logo>
        Mise à jour en cours
      </template>
    </div>
  </transition>
</template>

<script>
export default {
  name: "maj",
  data() {
    return {
      loader: false,
      show: false,
      first: false,
    }
  },
  props: [ 'display' ],
  created() {
    this.sync()
    document.addEventListener("visibilitychange", () => {
      if(!document.hidden) {
        this.sync()
        console.log('check')
      }
    })
  },
  methods: {
    sync() {
      if ('serviceWorker' in navigator) {
        if(this.display && !this.first)
          this.show = true

        this.first = true
        if(this.$content && this.$content.version) {
          this.checkVersion(this.$content.version)
        }
        else {
          this.$http.get('https://api.pleitek.com/creativeapi/project/version').then((data) => {
            const { version } = data.data
            this.checkVersion(version)
          }).catch(() => this.show = false)
        }
      }
    },
    checkVersion(version) {
      let thisVersion = localStorage.getItem('appVersion')
      if(!thisVersion) {
        this.show = false
        localStorage.setItem('appVersion', version)
      }
      else if(thisVersion !== version) {
        this.loader = true
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          registrations.map(r => {
            r.unregister()
          })
        })
        localStorage.setItem('appVersion', version)
        setTimeout(function () {

          window.location.reload(true)
        }, 1000)
      }
      else {
        this.show = false
      }
    }
  }
}
</script>

<style scoped>
.maj { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: #fff; display: flex; flex-direction: column; align-items: center; justify-content: center;
  font-weight: 500; font-size: 1.4rem; color: #777; animation: load 1s; z-index: 900;
}

.logo { margin-bottom: 16px;  }

@keyframes load {
  0% { filter: grayscale(100%) }
  100% { filter: grayscale(0%) }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .33s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>